<template>
  <div class="scanner" v-if="this.showScanner">
    <div class="hd">{{$t('Please scan the QE Code')}}</div>
    <div class="fl-group" v-if="this.error != ''">
      <div class="bd">
        <!--錯誤訊息-->
        <p class="p2">{{this.error}}</p>
      </div>
    </div>
    <qrcode-stream @decode="onDecode" @init="onInit" :camera="front" :track="paintOutline">
      <div v-if="this.loading" class="validation-pending">
        {{$t('Please Wait...')}}
      </div>
    </qrcode-stream>
  </div>
  <div class="scanner" v-else>
    <div class="hd">{{$t('Ticket Detail')}}</div>
    <div class="fl-group">
      <div class="bd">
        <!--訂單編號-->
        <p class="p2">{{$t('Order number')}}：{{orderInfo.orderId}}</p>
        <!--預定時間-->
        <p class="p2">{{$t('Purchase Date')}}：{{orderInfo.addDate | formatDate('yyyy-MM-dd hh:mm')}}</p>
      </div>
    </div>

    <div class="hd">{{$t('OrderInformation')}}</div>
    <div class="fl-group">
      <div class="bd">
        <!--產品名稱-->
        <p class="p2">{{$t('ProductName')}}：{{order.productTitle}}</p>
        <!--產品名稱-->
        <p class="p2">{{$t('Type')}}：{{order.lineTitle}}</p>
        <!--有效日期-->
        <p class="p2">{{$t('Expiry Date')}} (YYYY-MM-DD)：{{order.priceDate}}</p>
        <!--訂單狀態-->
        <p class="p2">{{$t('Redeem Date')}}(YYYY-MM-DD)：{{order.redeemTicketDate | formatDate('yyyy-MM-dd hh:mm')}}</p>
        <!--兌換日期-->
        <p class="p2">{{$t('Order status')}}：{{$t('TicketStatus')[order.orderStatus]}}</p>
        <!--付款狀態-->
        <p class="p2">{{$t('Trade status')}}：{{$t('paymentStatus')[order.paymentStatus]}}</p>
        <!--成人數量-->
        <p class="p2">{{$t('Adult')}}：{{orderInfo.adultNumber}}</p>
        <!--長者數量-->
        <p class="p2">{{$t('Elderly')}}：{{orderInfo.elderlyNumber}}</p>
        <!--小童數量-->
        <p class="p2">{{$t('Child')}}：{{(orderInfo.childNumber + orderInfo.notChildNumber)}}</p>
        <!--總人數-->
        <p class="p2">{{$t('Number of people')}}：</p>
        <p>
          {{orderInfo.adultNumber+orderInfo.childNumber+orderInfo.notChildNumber+orderInfo.babyNumber}}
        </p>
      </div>
    </div>
    <div class="hd" v-if="this.order.orderStatus=='NOT_SIGN_UP_SUCCESS'"><el-button type="primary" class="redeemButton" plain @click="clickRedeem">{{$t('Redeem')}}</el-button></div>
    <div class="hd" v-else><el-button type="primary" class="redeemButton" plain @click="clickRedeem" :disabled="true">{{$t('TicketStatus')[order.orderStatus]}}</el-button></div>
    <div class="hd" v-if="this.scannerCode!=''"><el-button type="success" class="redeemButton" plain @click="reloadPage">{{$t('Scan another QR Code')}}</el-button></div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import {redeemTicket, verifyTicket} from '@/api/scanner';
export default {
  components: { QrcodeStream },
  data() {
    return {
      loading: false,
      showScanner: true,
      scannerCode: '',
      orderInfo: [],
      order: [],
      error: '',
      ticketExpireMsg: ''
    };
  },
  methods: {
    reloadPage(){
      window.location.reload();
    },
    clickRedeem(){
      this.$confirm(this.ticketExpireMsg + '\n確定要兌換門票嗎？', '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: 'redeem-dialog',
        center: true
      }).then(() => {
        redeemTicket(this.scannerCode).then( res => {
          this.verifyTicket(this.scannerCode);
        });
      }).catch(() => {
      });
    },
    onDecode(result) {
      this.scannerCode = result;
      this.loading = true;
      this.verifyTicket(result);
    },
    verifyTicket(scannerCode){
      verifyTicket(scannerCode).then( res => {
        this.orderInfo = res.value.orderDetails;
        this.order = res.value.order;
        const ticketExpired = new Date(this.order.priceDate).setHours(23, 59, 59, 999);
        if (ticketExpired < Date.now()) {
          this.ticketExpireMsg = '有效日期已過！';
        } else if (new Date(this.order.priceDate).setHours(0, 0, 0, 0) > Date.now()) {
          this.ticketExpireMsg = '尚未到有效日期！';
        }
        this.showScanner = false;
        this.loading = false;
      });
    },
    paintOutline (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints;

        ctx.strokeStyle = 'red';

        ctx.beginPath();
        ctx.lineWidth = 5;
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = '請提供相機權限給本網站';
        } else if (error.name === 'NotFoundError') {
          this.error = '本裝置未偵測到任何相機';
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)';
        } else if (error.name === 'NotReadableError') {
          this.error = '相機被其他程式使用中';
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser';
        } else if (error.name === 'InsecureContextError') {
          this.error =
            'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    }
  }
};
</script>
<style>
.redeem-dialog {
  max-width: 340px;
  width: 90% !important;
}
</style>

<style scoped lang="less">
.error {
  font-weight: bold;
  color: red;
}
@media only screen and (min-width: 501px) {
  .scanner{
    padding: 0 30px 0 30px;
    .hd{margin-top:30px;text-align:center;font-size:24px;}
    .item{
      float:left;width:300px;margin-top:30px;margin-right:15px;margin-left:15px;overflow:hidden;cursor:pointer;color:#321908;border-radius:4px;box-shadow:0 2px 8px 0 rgba(255, 115, 101, 0.20);min-height: 355px;
    }
    .cover{position:relative;height:200px;overflow:hidden;}
    .infos{padding:16px;}
    .btn-collect{position:absolute;top:16px;right:16px;}
    .star1{float:left;margin-left:-4px;}
    .travel-agency{margin-left:120px;text-align:right;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
    .title{display:-webkit-box;height:50px;margin-top:10px;line-height:25px;overflow:hidden;-webkit-line-clamp:2;-webkit-box-orient:vertical;font-size:16px;}
    .price-wrap{margin:0 8px 20px;}
    .price{float:right;line-height:1;color:#ff6f61;font-size:22px;}
    .unit{color:#666;font-size:14px;}
    .fcs1{display:inline-block;vertical-align:middle;color:#999;}
  }
}

@media only screen and (max-width: 500px) {
  .scanner {
    .hd {
      margin-top: 30px;
      text-align: center;
      font-size: 24px;
    }

    .item {
      float: left;
      width: 300px;
      margin-top: 30px;
      margin-right: 15px;
      margin-left: 15px;
      overflow: hidden;
      cursor: pointer;
      color: #321908;
      border-radius: 4px;
      box-shadow: 0 2px 8px 0 rgba(255, 115, 101, 0.20);
      min-height: 355px;
    }

    .cover {
      position: relative;
      height: 200px;
      overflow: hidden;
    }

    .infos {
      padding: 16px;
    }

    .btn-collect {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .star1 {
      float: left;
      margin-left: -4px;
    }

    .travel-agency {
      margin-left: 120px;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .title {
      display: -webkit-box;
      height: 50px;
      margin-top: 10px;
      line-height: 25px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 16px;
    }

    .price-wrap {
      margin: 0 8px 20px;
    }

    .price {
      float: right;
      line-height: 1;
      color: #ff6f61;
      font-size: 22px;
    }

    .unit {
      color: #666;
      font-size: 14px;
    }

    .fcs1 {
      display: inline-block;
      vertical-align: middle;
      color: #999;
    }
  }
}

.redeemButton {
  width: 50%;
  font-size: 1rem;
}

.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.fl-group{
  .hd{
    position:relative;margin:30px 0 20px;padding-left:16px;font-size:24px;
    &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
  }
  .bd{
    padding:20px;background-color:#f4f4f4;border-radius:4px;
    &:not(:first-child){margin-top:8px;}
  }
  .actions{margin-top:20px;text-align:right;}
  .p2{
    line-height:32px;color:#321908;font-size:16px;
    .b{font-weight:bold;}
  }
}
</style>

import request from '@/utils/request';

export function verifyTicket(scannerCode) {
  return request({
    url: `/order/ticket/verify/${scannerCode}`,
    method: 'get'
  });
}
export function redeemTicket(orderID) {
  return request({
    url: `/order/ticket/redeem/${orderID}`,
    method: 'get'
  });
}
